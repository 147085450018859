import { Suspense, useEffect, useRef } from "react"
import styled from "styled-components"
import { Canvas } from "@react-three/fiber"
import * as THREE from "three"

import GetWorldInfo from "@components/GetWorldInfo/GetWorldInfo"

import { useStore } from "@state/store"
import Picker360 from "@components/Picker360/Picker360"

function XRTabletController() {
  const worldInfo = useStore((s) => s.worldInfo)
  const currentTour = useStore((s) => s.currentTour)
  const setCurrentTour = useStore((s) => s.setCurrentTour)
  const socket = useStore((s) => s.socket)
  const renderer = useRef<any>(null)

  const array = [
    "/textures/landsec/mini/1.webp",
    "/textures/landsec/mini/2.webp",
    "/textures/landsec/mini/3.webp",
    "/textures/landsec/mini/4.webp",
    "/textures/landsec/mini/5.webp",
    "/textures/landsec/mini/6.webp",
    "/textures/landsec/mini/7.webp",
  ]

  const imgs = {
    main: [
      {
        src: "/textures/landsec/mini/1.webp",
        index: 0,
      },
    ],
    second: [
      {
        src: "/textures/landsec/mini/2.webp",
        index: 1,
      },
    ],
    west: [
      {
        src: "/textures/landsec/mini/3.webp",
        index: 2,
      },
    ],
    server: [
      {
        src: "/textures/landsec/mini/4.webp",
        index: 3,
      },
    ],
    pocket: [
      {
        src: "/textures/landsec/mini/5.webp",
        index: 4,
      },
    ],
    substation: [
      {
        src: "/textures/landsec/mini/6.webp",
        index: 5,
      },
    ],
    aerial: [
      {
        src: "/textures/landsec/mini/7.webp",
        index: 6,
      },
    ],
  }

  const description = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
  ]

  useEffect(() => {
    if (worldInfo) {
      renderer.current = worldInfo.gl
    }
  }, [worldInfo])
  return (
    <TabletWrapper>
      <Suspense fallback={null}>
        <Canvas camera={{ position: new THREE.Vector3(0, 0, 0) }}>
          <GetWorldInfo />
        </Canvas>
      </Suspense>
      <Controls>
        <div style={{display: "flex", flexDirection:"column", width: "50%", height:"100%"}}>
        <Picker360
          activeIndexes={[0]}
          arr={imgs.main}
          title={["Main Security Entrance"]}
          subtitle={description[currentTour]}
        />
        <Picker360
          activeIndexes={[1]}
          arr={imgs.second}
          title={["Second Entrance"]}
          subtitle={description[currentTour]}
        />
        <Picker360
          activeIndexes={[2]}
          arr={imgs.west}
          title={["West Site"]}
          subtitle={description[currentTour]}
        />
        <Picker360
          activeIndexes={[3]}
          arr={imgs.server}
          title={["Server Room"]}
          subtitle={description[currentTour]}
        />
        </div>
        <div style={{display: "flex", flexDirection:"column", width:"50%", height: "100%"}}>
        <Picker360
          activeIndexes={[4]}
          arr={imgs.pocket}
          title={["Pocket Park"]}
          subtitle={description[currentTour]}
        />
        <Picker360
          activeIndexes={[5]}
          arr={imgs.substation}
          title={["Substation"]}
          subtitle={description[currentTour]}
        />
        <Picker360
          activeIndexes={[6]}
          arr={imgs.aerial}
          title={["Aerial Site"]}
          subtitle={description[currentTour]}
        />
        </div>
      </Controls>
    </TabletWrapper>
  )
}
export default XRTabletController

const TabletWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

const Controls = styled.div`
  position: absolute;
  width: 85%;
  height: 100%;
  padding: 4rem ;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 2rem;
`
