const masterConfig = {
  rc: {
    master: true,
    tablet: true,
    color: null,
    user: null,
  },
  blue: {
    master: false,
    tablet: false,
    color: "blue",
    user: 3
  },
  green: {
    master: false,
    tablet: false,
    color: "green",
    user: 5
  },
  red: {
    master: false,
    tablet: false,
    color: "red",
    user: 6
  }, 
  white: {
    master: false,
    tablet: false,
    color: "white",
    user: 4
  },
  yellow: {
    master: false,
    tablet: false,
    color: "yellow",
    user: 2
  }
}

export default masterConfig
