import TabletLayout from "./layouts/TabletLayout"
import masterConfig from "./config"
import OculusLayout from "./layouts/OculusLayout"

function DefaultLayout() {
  const config = masterConfig[window.location.hostname.split(".")[0].split("-")[0]]

  return config.tablet ?   <TabletLayout /> : <OculusLayout />
}

export default DefaultLayout
