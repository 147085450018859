import styled from "styled-components"

import ControllerSideBar from "@components/ControllerSideBar/ControllerSideBar"
import HeadsetModal from "@components/HeadsetModal/HeadsetModal"
import ARScene from "@components/ARScene/ARScene"
import ARTour from "@components/ARTour/ARTour"
import VRIcon from "@components/SVG/VRIcon/VRIcon"
import XRTabletController from "@components/XRTabletController/XRTabletController"
import Icon360 from "@components/SVG/360Icon/Icon360"

import globalStyles from "@data/globalStyles"
import { useStore } from "@state/store"

function TabletController() {
  const AROpen = useStore((s) => s.AROpen)
  const setAROpen = useStore((s) => s.setAROpen)
  const ARTourOpen = useStore((s) => s.ARTourOpen)
  const setARTourOpen = useStore((s) => s.setARTourOpen)

  return (
    <ControllerWrapper>
      <ControllerSideBar />
      <HeadsetModal />
      <Content>
        <XRTabletController />
      </Content>
      <ARScene />
      <ARTour />
      <OpenAR
        onClick={() => {
          !AROpen && setARTourOpen(!ARTourOpen)
        }}
      >
        <Icon360
          cue={!ARTourOpen}
          strokeWidth={5}
          strokeColor={globalStyles.colors.dark.main}
        />
      </OpenAR>
    </ControllerWrapper>
  )
}

export default TabletController

const ControllerWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${globalStyles.colors.dark.main};
  background-image: url("/images/map.jpg");

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: ${globalStyles.colors.dark.main};
    opacity: 0.75;
    box-shadow: inset 0px 0px 200px 100px #000d0f;
  }
`

const Content = styled.div`
  position: absolute;
  right: 0;
  height: 100%;
  width: calc(100% - 15vh);
  z-index: 100;
`

const OpenAR = styled.div`
  position: absolute;
  z-index: 100;
  top: 2vw;
  right: 2vw;
  background-color: ${globalStyles.colors.dark.accent};
  box-shadow: 0rem 0rem 1.5rem ${globalStyles.colors.dark.accent}44;
  border-radius: 100%;
  cursor: pointer;
  padding: 1rem;

  & svg {
    margin: auto;
    width: 30px;
    aspect-ratio: 1/1;
  }
`
